.nav-button {
    .nav-tabs {
        border-bottom: none;

        > li {
            display: inline-block;
            float: none;
            margin-bottom: 0;

            > a {
                border: 1px solid #fff;
                text-transform: uppercase;

                &:hover {
                    border: 1px solid #c1c1c1;
                }
            }

            &.active > a {
                border: 1px solid #1f3663;
                color: #fff;
                background: #1f3663;
                cursor: default;
            }
        }
    }
}
